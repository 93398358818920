import { createVNode, render } from 'vue'
import Message from '~/components/Base/Message.vue'

type MessageType = 'default' | 'success' | 'warning' | 'error'
const DURATION_TIME = 2000

interface MessageProps {
  msg: string
  type?: MessageType
  visible?: boolean
  duration?: number
}

function createMessage(props: MessageProps) {
  if (process.client) {
    const id = '__msg__'
    const div = document.querySelector(`#${id}`)

    if (!props.visible) {
      return
    }
    if (div) {
      document.body.removeChild(div)
    }

    const container = document.createElement('div')
    container.id = id
    const vm = createVNode(Message, { id, ...props })
    render(vm, container)
    document.body.appendChild(container)
  }
}

export const MessageEle = {
  success(arg: MessageProps | string) {
    createMessage({
      type: 'success',
      visible: true,
      msg: typeof arg === 'string' ? arg : arg?.msg,
      duration: typeof arg === 'string' ? DURATION_TIME : arg?.duration
    })
  },

  warning(arg: MessageProps | string) {
    createMessage({
      type: 'warning',
      visible: true,
      msg: typeof arg === 'string' ? arg : arg?.msg,
      duration: typeof arg === 'string' ? DURATION_TIME : arg?.duration
    })
  },

  error(arg: MessageProps | string) {
    createMessage({
      type: 'error',
      visible: true,
      msg: typeof arg === 'string' ? arg : arg?.msg,
      duration: typeof arg === 'string' ? DURATION_TIME : arg?.duration
    })
  }
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      message: MessageEle
    }
  }
})
