// import BaseIcon from '~/components/Base/Icon.vue'
import 'virtual:svg-icons-register'
import 'vant/lib/index.css'
import {
  Tab,
  Tabbar,
  TabbarItem,
  Tabs,
  NavBar,
  List,
  PullRefresh,
  Sticky,
  Cell,
  CellGroup,
  Row,
  Col,
  Swipe,
  SwipeItem,
  Popup,
  Overlay,
  Toast,
  Collapse,
  Checkbox,
  CheckboxGroup,
  CollapseItem,
  Field,
  Loading,
  NoticeBar,
  ActionSheet,
  Uploader,
  Icon,
  PasswordInput,
  NumberKeyboard,
  Calendar,
  Dialog,
  Search
} from 'vant'

export default defineNuxtPlugin(({ vueApp }) => {
  // vueApp.component('BaseIcon', BaseIcon)
  vueApp
    .use(Tabs)
    .use(Tab)
    .use(Tabbar)
    .use(TabbarItem)
    .use(NavBar)
    .use(List)
    .use(PullRefresh)
    .use(Sticky)
    .use(Cell)
    .use(CellGroup)
    .use(Row)
    .use(Swipe)
    .use(SwipeItem)
    .use(Popup)
    .use(Overlay)
    .use(Toast)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(Collapse)
    .use(CollapseItem)
    .use(Field)
    .use(Loading)
    .use(NoticeBar)
    .use(Col)
    .use(ActionSheet)
    .use(Uploader)
    .use(Icon)
    .use(PasswordInput)
    .use(NumberKeyboard)
    .use(Calendar)
    .use(Dialog)
    .use(Search)
})
