import { NoticeApi } from '~/net/apis/notice_api'

export const useHome = defineStore('Home', {
  state: () => ({
    popupShow: true,
    Notice: <any[]>[],
    groupName: ''
  }),
  getters: {
    AdcolumnStatus: (state) => {
      return state.popupShow
    },
    NoticeStatus: (state) => {
      return state.Notice
    },
    groupNameStatus: (state) => {
      return state.groupName
    }
  },

  actions: {
    setGroupName(name: string) {
      this.groupName = name
    },
    setAdColumn(bol: boolean) {
      this.popupShow = bol
    },
    async getNotice() {
      try {
        const { data } = await NoticeApi.specialClient()
        if (!Array.isArray(data)) {
          return
        }
        data.map((item) => {
          this.Notice.push({
            status: item.popStatus,
            popUrl: item.popUrl,
            targetUrl: item.popContent
          })
        })
      } catch (error) {}
    }
  }
})
