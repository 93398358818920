import revive_payload_client_uPxKO1sFly from "/Users/lbg/work/vb/front/jav-h5/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_kenrmowiju7hxfujr6zpbyloii/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HdPDPPKEuL from "/Users/lbg/work/vb/front/jav-h5/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_kenrmowiju7hxfujr6zpbyloii/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_BBbibXRcPD from "/Users/lbg/work/vb/front/jav-h5/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_kenrmowiju7hxfujr6zpbyloii/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_sWBxQbvUq4 from "/Users/lbg/work/vb/front/jav-h5/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_kenrmowiju7hxfujr6zpbyloii/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_h6gzsVevYC from "/Users/lbg/work/vb/front/jav-h5/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_kenrmowiju7hxfujr6zpbyloii/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_EyggPQCUiB from "/Users/lbg/work/vb/front/jav-h5/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_kenrmowiju7hxfujr6zpbyloii/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_rAVtZLlPpV from "/Users/lbg/work/vb/front/jav-h5/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.4_rollup@4.18.0_typescript@5.0.2_vue@3.4.31_typescript@5.0.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/lbg/work/vb/front/jav-h5/.nuxt/components.plugin.mjs";
import prefetch_client_L5PDpNNRSo from "/Users/lbg/work/vb/front/jav-h5/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_kenrmowiju7hxfujr6zpbyloii/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_1tsy7HIxIh from "/Users/lbg/work/vb/front/jav-h5/node_modules/.pnpm/dayjs-nuxt@2.1.9_magicast@0.3.4_rollup@4.18.0/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_dBLwxDLlTU from "/Users/lbg/work/vb/front/jav-h5/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/Users/lbg/work/vb/front/jav-h5/.nuxt/unocss.mjs";
import icon_client_shIZuwSQPw from "/Users/lbg/work/vb/front/jav-h5/plugins/icon.client.ts";
import message_client_gNVFdLD78f from "/Users/lbg/work/vb/front/jav-h5/plugins/message.client.ts";
export default [
  revive_payload_client_uPxKO1sFly,
  unhead_HdPDPPKEuL,
  router_BBbibXRcPD,
  navigation_repaint_client_sWBxQbvUq4,
  check_outdated_build_client_h6gzsVevYC,
  chunk_reload_client_EyggPQCUiB,
  plugin_vue3_rAVtZLlPpV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_L5PDpNNRSo,
  plugin_1tsy7HIxIh,
  plugin_dBLwxDLlTU,
  unocss_MzCDxu9LMj,
  icon_client_shIZuwSQPw,
  message_client_gNVFdLD78f
]