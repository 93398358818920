import fingerprintjs2 from 'fingerprintjs2'
import Cache from '~/utils/cache'

export function getBrowserFinger() {
  return new Promise<string>((resolve) => {
    if (Cache.fingers()) {
      resolve(Cache.fingers() as string)
    }
    fingerprintjs2.get((components) => {
      const values = components.map((component) => {
        return component.value
      })
      const murmur = fingerprintjs2.x64hash128(values.join(''), 31)
      process.client && Cache.setFingers(murmur)
      resolve(murmur)
    })
  })
}
