import { decryptImgUrl } from '~/utils/crypto'

interface ImgDecryptType {
  content: Array<any> | any
  field?: string
}
export function handleImgDecryptImg({ content, field }: ImgDecryptType) {
  try {
    if (Array.isArray(content) && field) {
      return content.map(async (item: any) => {
        return new Promise((resolve) => {
          decryptImgUrl(item[field]).then((url: string) => {
            item[field] = url
            resolve({ status: 1 })
          })
        })
      })
    }
    if (Object.prototype.toString.call(content) === '[object Object]' && field) {
      return new Promise((resolve) => {
        decryptImgUrl(content[field]).then((url: string) => {
          content[field] = url
          resolve({ status: 1 })
        })
      })
    }
    if (Object.prototype.toString.call(content) === '[object String]') {
      return decryptImgUrl(content)
    }
  } catch (e) {
    console.error(`decrypt img fail ${e}`)
  }
}
