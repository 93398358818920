// import Cache from '~/utils/cache'

export default defineNuxtRouteMiddleware(async (to) => {
  // const userStore = useUser()
  // 切换路由滚动条回到顶部
  // if (process.client) {
  //   try {
  //     Cache.token() && !userStore.userInfo.id && (await userStore.handleUserInfo())
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
})
