import { cryptoUtils } from './core'
import { getNewPic } from '~/utils/helper'
const appKey = import.meta.env.VITE_APP_KEY

// 数据加密
function encryptData(data: any) {
  data = data || {}
  try {
    return cryptoUtils.encrypt(data, appKey)
  } catch (error) {
    console.error(error)
  }
}

// 数据解密
function decryptData(data: any) {
  try {
    return cryptoUtils.decrypt(data, appKey)
  } catch (error) {
    console.error(error)
  }
}

// 图片解密
function decryptImgUrl(newurl: string) {
  const url = getNewPic(newurl)
  return new Promise<string>((resolve) => {
    $fetch(url, {
      responseType: 'text'
    })
      .then((res) => {
        try {
          const realUrl = `data:image/png;base64,${cryptoUtils.decryptImg(
            res as string,
            import.meta.env.VITE_APP_IMG_KEY
          )}`
          resolve(realUrl)
        } catch (error) {
          resolve('data:image/png;base64')
          console.warn(error)
        }
      })
      .catch(() => {
        resolve('error')
      })
  })
}
export { cryptoUtils, encryptData, decryptData, decryptImgUrl }
