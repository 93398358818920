<script setup lang="ts">
type MessageType = 'default' | 'success' | 'warning' | 'error'

const props = withDefaults(
  defineProps<{
    msg?: string
    type?: MessageType
    duration?: number
    id?: string
    visible?: boolean
  }>(),
  {
    duration: 1200,
    type: 'default',
    visible: true,
    id: '__msg__'
  }
)
const timer = ref<any>(undefined)
const msgVisible = ref<boolean>(true)

function showMsg() {
  if (!msgVisible.value) {
    return
  }

  timer.value = setTimeout(() => {
    msgVisible.value = false
  }, props.duration)
}

const calculateClass = computed(() => {
  // switch (props.type) {
  //   case 'success':
  //     return 'bg-f0f9eb ct-67c23a'
  //   case 'warning':
  //     return 'bg-f0f9eb ct-67c23a'
  //   case 'default':
  //     return 'bg-f0f9eb ct-67c23a'
  //   case 'error':
  //     return 'bg-ffd4d4 ct-E90A0AFF'
  //   default:
  //     return 'bg-f0f9eb ct-67c23a'
  // }
})

onMounted(() => {
  msgVisible.value = props.visible
  showMsg()
})

watch(
  () => msgVisible.value,
  (newV) => {
    if (!newV) {
      const transTimer = setTimeout(() => {
        if (document.getElementById(`${props.id}`)) {
          document.body.removeChild(document.getElementById(`${props.id}`) as Element)
        }
        clearTimeout(transTimer)
        clearTimeout(timer.value)
        timer.value = undefined
      }, 400)
    }
  }
)
</script>

<template>
  <transition name="message-fade">
    <div
      v-if="msgVisible"
      flex="~ items-center"
      class="left-1/2 top-1/2 translate-x--1/2"
      :class="calculateClass"
      pos-fixed
      z-999
      mx-a
      w-80
      border-rd-1
      px-6
      py-2
      text-center
      shadow-md
      bg-353950
      ct-e8e8e8
    >
      <p flex-1>
        {{ msg }}
      </p>
    </div>
  </transition>
</template>

<style scoped>
.message-fade-enter-active,
.message-fade-leave-active {
  transition:
    opacity 0.2s,
    transform 0.3s;
}

.message-fade-enter,
.message-fade-leave-to {
  opacity: 0;
  transform: translate(-50%, -30px);
}
</style>
