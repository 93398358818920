import { default as indexuwdFOqgOI6Meta } from "/Users/lbg/work/vb/front/jav-h5/pages/activity/index.vue?macro=true";
import { default as _91id_93Z1OIaroVEbMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/activityDetail/[id].vue?macro=true";
import { default as indexWdQVSWyVbTMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/activityIframe/index.vue?macro=true";
import { default as indexh2TCIjfCKmMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/AdIframe/index.vue?macro=true";
import { default as indexjD8ojeEZVYMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/bindPhone/index.vue?macro=true";
import { default as index6HBMAOThDYMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/boon/index.vue?macro=true";
import { default as indexiD5Bu2S5T8Meta } from "/Users/lbg/work/vb/front/jav-h5/pages/browsHistory/index.vue?macro=true";
import { default as index0YFbYlxk0AMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/cdkGift/index.vue?macro=true";
import { default as indexypFWrK0CBeMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/changeAcount/index.vue?macro=true";
import { default as _91type_933nTC7HizROMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/channel/[type].vue?macro=true";
import { default as indexqwr3AO9oJ7Meta } from "/Users/lbg/work/vb/front/jav-h5/pages/demo/img-load/index.vue?macro=true";
import { default as indexwfjPui7IWxMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/demo/player/index.vue?macro=true";
import { default as indexr1Z9l6IWMuMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/demo/utils/index.vue?macro=true";
import { default as _91id_93ZnsT1zaZvvMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/details/[type]/[id].vue?macro=true";
import { default as indexiNewx368utMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/feedback/index.vue?macro=true";
import { default as index26fKVzCuFEMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/flowDetail/index.vue?macro=true";
import { default as indexYA6WQedVHwMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/flowRecord/index.vue?macro=true";
import { default as indexEX24BhHaUeMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/group/index.vue?macro=true";
import { default as _91groupId_93yO3XnECSZQMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/home-combine/[groupId].vue?macro=true";
import { default as indexkZyvoyEJxbMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/home-free/index.vue?macro=true";
import { default as indexqgxD5GUl1KMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/home-vip/index.vue?macro=true";
import { default as index426qAdKr4iMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/id-card-find/index.vue?macro=true";
import { default as indexBiXr9U6nQjMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/index/index.vue?macro=true";
import { default as indexVsVqdp9jLOMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/memberRecharge/index.vue?macro=true";
import { default as _91producerId_93ZbOrjLbJn7Meta } from "/Users/lbg/work/vb/front/jav-h5/pages/merchant_details/[producerId].vue?macro=true";
import { default as indexui9irn1yIDMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/merchant/index.vue?macro=true";
import { default as indexBnaMVIvc23Meta } from "/Users/lbg/work/vb/front/jav-h5/pages/mine/index.vue?macro=true";
import { default as indexsvkwIdDUbHMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/myCollected/index.vue?macro=true";
import { default as index_32copytE62RgVbHRMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/myDownload/index copy.vue?macro=true";
import { default as indexvuRRNRoQKWMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/myDownload/index.vue?macro=true";
import { default as indexTpYQf1IcW5Meta } from "/Users/lbg/work/vb/front/jav-h5/pages/payResult/index.vue?macro=true";
import { default as _91serial_93hpT68XUfuVMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/play/[type]/[id]/[serial].vue?macro=true";
import { default as _91id_93kMb7iIxsoWMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/player/[id].vue?macro=true";
import { default as indexp6Qyjlt6QgMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/profile/index.vue?macro=true";
import { default as indexSFIyMvsdWLMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/promote-share-record/index.vue?macro=true";
import { default as indexqB90525zs1Meta } from "/Users/lbg/work/vb/front/jav-h5/pages/promote-share/index.vue?macro=true";
import { default as indexaL9I8XYtMaMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/purchase-history/index.vue?macro=true";
import { default as _91rankType_93Ofn2YM6dCtMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/ranking-list/all/[periodType]/[rankType].vue?macro=true";
import { default as index0ynbTMrdbWMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/ranking-list/index.vue?macro=true";
import { default as index9qzezyIyPuMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/recharge/index.vue?macro=true";
import { default as _91keyword_93PUVDVzJeUhMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/search/[keyword].vue?macro=true";
import { default as indexFiDYnvd7uuMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/service/index.vue?macro=true";
import { default as indexdVBKPLk0WnMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/sis/index.vue?macro=true";
import { default as indexfeFGF713LsMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/taskCenter/index.vue?macro=true";
import { default as indexmnpjPGF0lbMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/usePhone/index.vue?macro=true";
import { default as indexDyRdc3VxnbMeta } from "/Users/lbg/work/vb/front/jav-h5/pages/user/index.vue?macro=true";
export default [
  {
    name: "activity",
    path: "/activity",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/activity/index.vue").then(m => m.default || m)
  },
  {
    name: "activityDetail-id",
    path: "/activityDetail/:id()",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/activityDetail/[id].vue").then(m => m.default || m)
  },
  {
    name: "activityIframe",
    path: "/activityIframe",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/activityIframe/index.vue").then(m => m.default || m)
  },
  {
    name: "AdIframe",
    path: "/AdIframe",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/AdIframe/index.vue").then(m => m.default || m)
  },
  {
    name: "bindPhone",
    path: "/bindPhone",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/bindPhone/index.vue").then(m => m.default || m)
  },
  {
    name: "boon",
    path: "/boon",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/boon/index.vue").then(m => m.default || m)
  },
  {
    name: "browsHistory",
    path: "/browsHistory",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/browsHistory/index.vue").then(m => m.default || m)
  },
  {
    name: "cdkGift",
    path: "/cdkGift",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/cdkGift/index.vue").then(m => m.default || m)
  },
  {
    name: "changeAcount",
    path: "/changeAcount",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/changeAcount/index.vue").then(m => m.default || m)
  },
  {
    name: "channel-type",
    path: "/channel/:type()",
    meta: _91type_933nTC7HizROMeta || {},
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/channel/[type].vue").then(m => m.default || m)
  },
  {
    name: "demo-img-load",
    path: "/demo/img-load",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/demo/img-load/index.vue").then(m => m.default || m)
  },
  {
    name: "demo-player",
    path: "/demo/player",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/demo/player/index.vue").then(m => m.default || m)
  },
  {
    name: "demo-utils",
    path: "/demo/utils",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/demo/utils/index.vue").then(m => m.default || m)
  },
  {
    name: "details-type-id",
    path: "/details/:type()/:id()",
    meta: _91id_93ZnsT1zaZvvMeta || {},
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/details/[type]/[id].vue").then(m => m.default || m)
  },
  {
    name: "feedback",
    path: "/feedback",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/feedback/index.vue").then(m => m.default || m)
  },
  {
    name: "flowDetail",
    path: "/flowDetail",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/flowDetail/index.vue").then(m => m.default || m)
  },
  {
    name: "flowRecord",
    path: "/flowRecord",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/flowRecord/index.vue").then(m => m.default || m)
  },
  {
    name: "group",
    path: "/group",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/group/index.vue").then(m => m.default || m)
  },
  {
    name: "home-combine-groupId",
    path: "/home-combine/:groupId()",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/home-combine/[groupId].vue").then(m => m.default || m)
  },
  {
    name: "home-free",
    path: "/home-free",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/home-free/index.vue").then(m => m.default || m)
  },
  {
    name: "home-vip",
    path: "/home-vip",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/home-vip/index.vue").then(m => m.default || m)
  },
  {
    name: "id-card-find",
    path: "/id-card-find",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/id-card-find/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: "memberRecharge",
    path: "/memberRecharge",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/memberRecharge/index.vue").then(m => m.default || m)
  },
  {
    name: "merchant_details-producerId",
    path: "/merchant_details/:producerId()",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/merchant_details/[producerId].vue").then(m => m.default || m)
  },
  {
    name: "merchant",
    path: "/merchant",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/merchant/index.vue").then(m => m.default || m)
  },
  {
    name: "mine",
    path: "/mine",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/mine/index.vue").then(m => m.default || m)
  },
  {
    name: "myCollected",
    path: "/myCollected",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/myCollected/index.vue").then(m => m.default || m)
  },
  {
    name: "myDownload-index copy",
    path: "/myDownload/index%20copy",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/myDownload/index copy.vue").then(m => m.default || m)
  },
  {
    name: "myDownload",
    path: "/myDownload",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/myDownload/index.vue").then(m => m.default || m)
  },
  {
    name: "payResult",
    path: "/payResult",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/payResult/index.vue").then(m => m.default || m)
  },
  {
    name: "play-type-id-serial",
    path: "/play/:type()/:id()/:serial()",
    meta: _91serial_93hpT68XUfuVMeta || {},
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/play/[type]/[id]/[serial].vue").then(m => m.default || m)
  },
  {
    name: "player-id",
    path: "/player/:id()",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/player/[id].vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "promote-share-record",
    path: "/promote-share-record",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/promote-share-record/index.vue").then(m => m.default || m)
  },
  {
    name: "promote-share",
    path: "/promote-share",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/promote-share/index.vue").then(m => m.default || m)
  },
  {
    name: "purchase-history",
    path: "/purchase-history",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/purchase-history/index.vue").then(m => m.default || m)
  },
  {
    name: "ranking-list-all-periodType-rankType",
    path: "/ranking-list/all/:periodType()/:rankType()",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/ranking-list/all/[periodType]/[rankType].vue").then(m => m.default || m)
  },
  {
    name: "ranking-list",
    path: "/ranking-list",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/ranking-list/index.vue").then(m => m.default || m)
  },
  {
    name: "recharge",
    path: "/recharge",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/recharge/index.vue").then(m => m.default || m)
  },
  {
    name: "search-keyword",
    path: "/search/:keyword()",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: "service",
    path: "/service",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/service/index.vue").then(m => m.default || m)
  },
  {
    name: "sis",
    path: "/sis",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/sis/index.vue").then(m => m.default || m)
  },
  {
    name: "taskCenter",
    path: "/taskCenter",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/taskCenter/index.vue").then(m => m.default || m)
  },
  {
    name: "usePhone",
    path: "/usePhone",
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/usePhone/index.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    meta: indexDyRdc3VxnbMeta || {},
    component: () => import("/Users/lbg/work/vb/front/jav-h5/pages/user/index.vue").then(m => m.default || m)
  }
]