import { cryptoUtils } from './crypto/core'
import Cache from '~/utils/cache'

interface AsyncWindowTypes {
  openWin: (url: string) => void
  closeWin: () => void
}

/* 滚动条回到顶部 */
export function scrollToTop(el?: HTMLElement | null) {
  // el = el ?? document.getElementById('scrollEl')
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

/**
 * 处理视屏类型
 * val 接口返回的类型
 */
export function getVideoType(val: number | string, flag?: boolean | undefined) {
  const tabsTypeList = [
    {
      type: 1,
      typeName: 'movie',
      title: '电影'
    },
    {
      type: 2,
      typeName: 'tv',
      title: '电视剧'
    },
    {
      type: 3,
      typeName: 'show',
      title: '综艺'
    },
    {
      type: 4,
      typeName: 'cartoon',
      title: '动漫'
    }
  ]

  if (flag) {
    const type: any = tabsTypeList.find((item) => {
      return item.typeName === val
    })
    return type.type
  } else {
    const type: any = tabsTypeList.find((item) => {
      return item.type === val
    })
    return type.typeName
  }
}
/* 获取当前域名信息 */
export function getDomainInfo() {
  return {
    hostname: window.location.hostname,
    href: window.location.href,
    protocol: window.location.protocol,
    port: window.location.port,
    pathname: window.location.pathname,
    origin: window.location.origin
  }
}

// md5加密处理
export function objToMd5(obj: string) {
  return cryptoUtils.md5(obj).toUpperCase()
}
/* 数组等分为几个小数组 */
export function splitArrayBySize(array: any[], size: number) {
  interface Type {
    [key: string]: any[]
  }

  const result: Type = {}
  const length = array.length
  let startIndex = 0
  let endIndex = size

  while (startIndex < length) {
    const subArray = array.slice(startIndex, endIndex)
    const range = `${startIndex + 1}-${endIndex}`
    result[range] = subArray
    startIndex = endIndex
    endIndex = Math.min(endIndex + size, length)
  }

  return result
}

export function openNewWindowAsync(callback: ({ openWin, closeWin }: AsyncWindowTypes) => void) {
  const win = window.open('')
  const openWin = (url: string) => win && (win.location = url)
  const closeWin = () => win?.close()
  win && callback({ openWin, closeWin })
}

export function getClientType(): string {
  if (!process.client) return
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return '3' // IOS_H5
  } else if (/Android/i.test(userAgent)) {
    return '4' // Android_H5
  } else {
    return '1' // PC
  }
}

export function getNewPic(urlOrPath: string): string {
  const Prefix = Cache.getPicPrefix()
  return getNewUrl(urlOrPath, Prefix || '')
}

export function getNewVideo(urlOrPath: string): string {
  const Prefix = Cache.getVideoPrefix()
  return getNewUrl(urlOrPath, Prefix || '')
}

function getNewUrl(urlOrPath: string, newPrefixUrl: string): string {
  const sanitizedPrefixUrl = newPrefixUrl.replace(/\/+$/, '')
  if (urlOrPath.startsWith('http')) {
    const path = new URL(urlOrPath).pathname
    return `${sanitizedPrefixUrl}${path}`
  } else {
    const sanitizedPath = urlOrPath.replace(/^\//, '')
    return `${sanitizedPrefixUrl}/${sanitizedPath}`
  }
}
