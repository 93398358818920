import type {
  AutoRegisterParams,
  AutoRegisterRes,
  LoginParams,
  LoginRes,
  PlayCollectVideo,
  RegisterParams,
  RegisterRes,
  UserInfoRes,
  UserModel,
  UserViewHistoryParams
} from './model'

import http from '~/utils/http'

// 注册接口
export function registerApi(params: RegisterParams) {
  return http.post<RegisterRes>({
    url: '/video/user/register',
    body: params
  })
}

// 自动注册
export function autoRegisterApi(registerParam: AutoRegisterParams) {
  return http.post<AutoRegisterRes>({
    url: '/jav/registerLogin',
    body: registerParam
  })
}

// 登录接口
export function loginApi(params: LoginParams) {
  return http.post<LoginRes>({
    url: '/video/user/login',
    body: params
  })
}
// 退出登录
export function logoutApi(params?: unknown) {
  return http.post({
    url: '/video/user/loginOut',
    body: params ?? undefined
  })
}
export function getUserInfoApi(params?: unknown) {
  return http.post<UserInfoRes>({
    url: '/video/user/getUserInfo',
    params: params ?? undefined
  })
}

export function getUserViewHistoryList(params: UserViewHistoryParams) {
  return http.post<UserModel>({
    url: '/api/user/view-history',
    body: params
  })
}

// 查询用户观看(收藏)视频列表
export function getPlayCollectVideo(params: PlayCollectVideo) {
  return http.post<any>({
    url: '/video/home/getPlayCollectVideoList',
    body: params
  })
}
