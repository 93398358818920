import validate from "/Users/lbg/work/vb/front/jav-h5/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_kenrmowiju7hxfujr6zpbyloii/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/Users/lbg/work/vb/front/jav-h5/middleware/auth.global.ts";
import manifest_45route_45rule from "/Users/lbg/work/vb/front/jav-h5/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.10_encoding@0.1.13_eslint@8.57.0_ioredis@_kenrmowiju7hxfujr6zpbyloii/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  user: () => import("/Users/lbg/work/vb/front/jav-h5/middleware/user.ts")
}