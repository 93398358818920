import type {
  BindPhoneParams,
  CodeWithPhoneParams,
  CommonDictParams,
  CommonDictRes,
  FindPasswordParams,
  FindPasswordRes,
  ReportDomainParams
} from '~/api/common/model'

import http from '~/utils/http'

// 已绑定电话号码的用户发送验证码
export function sendCodeWithoutPhoneApi(params?: unknown) {
  return http.post({
    url: '/commonApi/sendCodeWithoutPhone',
    body: params ?? undefined
  })
}
// 指定电话号码发送验证码
export function sendCodeWithPhoneApi(params: CodeWithPhoneParams) {
  return http.post({
    url: '/commonApi/sendCodeByPhoneWithoutUser',
    body: params
  })
}
// 绑定手机号
export function userBindPhoneApi(params: BindPhoneParams) {
  return http.post({
    url: '/video/user/bindPhone',
    body: params
  })
}

// 域名访问统计
export function reportDomainApi(params: ReportDomainParams) {
  return http.post({
    url: '/jav/config/addInviteCount',
    body: params
  })
}
// 找回密码
export function userFindPassword(params: FindPasswordParams) {
  return http.post<FindPasswordRes>({
    url: '/video/user/findPassword',
    body: params
  })
}

// 域名访问统计
export function getCommonDictApi(params: CommonDictParams) {
  return http.post<CommonDictRes[]>({
    url: '/jav/config/dict',
    body: params
  })
}

// /jav/common/communicateGroup 官网交流群跳转
// 域名访问统计
export function getGroup(params: any) {
  return http.post({
    url: '/jav/common/communicateGroup',
    body: params
  })
}