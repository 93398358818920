import { autoRegisterApi, getUserInfoApi, loginApi, logoutApi, registerApi } from '~/api/user'
import type { LoginParams, RegisterParams, UserInfoRes } from '~/api/user/model'
import Cache from '~/utils/cache'
import { getBrowserFinger } from '~/utils/finger'
import { getDomainInfo } from '~/utils/helper'

let STATA_OUT = 0
export const useUser = defineStore('user', {
  state: () => ({
    userInfo: <UserInfoRes>{}
  }),
  getters: {
    // 是否是正式用户登录
    isRealUser: (state) => {
      return state.userInfo.loginStatus === 1
    },
    // 是否绑定手机号
    isBindPhone: (state) => {
      return state.userInfo?.mobile?.length
    }
  },
  actions: {
    // 注册
    async handleRegister(args: RegisterParams) {
      const finger = await getBrowserFinger()
      const params = {
        ...args,
        registerReference: getDomainInfo().origin,
        deviceNo: finger,
        token: Cache.token()
      }
      const { data } = await registerApi(params)

      Cache.setToken(data.token)
      await this.handleUserInfo()
    },
    // 自动注册
    async handleAutoRegister() {
      if (this.isRealUser) {
        return
      }
      try {
        if (STATA_OUT > 5) {
          return
        }

        STATA_OUT++
        const finger = await getBrowserFinger()
        const { data } = await autoRegisterApi({
          registerReference: getDomainInfo().origin,
          deviceNo: finger,
          token: null,
          invitationCode: null,
          channelCode: null,
          iCode: null
        })

        Cache.setToken(data.token)
        await this.handleUserInfo()
      } catch (e) {
        console.log(e)
      }
    },
    // 登录
    async handleLogin(args: LoginParams) {
      const finger = await getBrowserFinger()
      const { data } = await loginApi({
        ...args,
        deviceNo: finger
      })
      Cache.setToken(data.token)
      await this.handleUserInfo()
    },

    /**
     * 登出方法
     * @param type 1 手动登出；2 自动登出
     */
    async handleLogout(type: number) {
      useRoute().path === '/user' && (await useRouter().push('/'))

      if (Cache.token()) {
        type === 1 && (await logoutApi())
      }

      Cache.clearCache()
      this.$reset()
      await this.handleAutoRegister()
    },
    // 获取个人信息
    async handleUserInfo() {
      if (!Cache.token()) {
        return
      }

      const { data } = await getUserInfoApi()
      await handleImgDecryptImg({
        content: data,
        field: 'topImagesUrl'
      })
      this.userInfo = data
      Cache.setLoginStatus(data.loginStatus)
    }
  }
})
