<script setup>
useHead({
  charset: 'utf-8',
  title: import.meta.env.VITE_APP_TITLE,
  titleTemplate: (title) =>
    title === import.meta.env.VITE_APP_TITLE ? title : `${title}-${import.meta.env.VITE_APP_TITLE}`,
  link: [
    {
      rel: 'icon',
      href: '/favicon.png',
      sizes: 'any'
    },
    {
      rel: 'stylesheet',
      href: '//unpkg.pstatp.com/xgplayer/3.0.0-alpha.110-18/dist/xgplayer.min.css'
    }
  ],
  meta: [
    {
      name: 'viewport',
      content: 'width=device-width,initial-scale=1,user-scalable=no'
    },
    {
      name: 'description',
      content: '微勃视频，海量高清成人影片在线免费观看，黑料热瓜、绯闻曝光一手独家揭秘'
    }
  ],
  script: [{ src: '/js/rem.js' }, { src: '/js/google-analytics.js?v=2-6' }]
})
const router = useRouter()
const nuxtApp = useNuxtApp()
nuxtApp.hook('vue:error', (e) => {
  console.log(e)
})

if (process.client) {
  router.push('/boon')
  try {
    await useUser().handleUserInfo()
  } catch (error) {}
  await useUser().handleAutoRegister()
  useCommon().reportDomain()

  try {
    await useHome().getNotice()
  } catch (error) {
    console.log('error>>>', error)
  }
}
</script>

<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
html,
body,
#__nuxt {
  /* position: relative; */
  /* height: 100vh; */
  background: #111425;
  color: #333;
  color-scheme: dark;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.van-tabs__nav {
  background: #111425;
  color: #e8e8e8;
  font-size: 14px;
}
.van-tab {
  color: #fff;
}
.van-tab--active {
  color: #fd5f03;
  font-size: 18px;
}
.van-tabs__line {
  width: 0;
}
.van-nav-bar {
  background: transparent;
}
.van-nav-bar__content {
  background: transparent;
}
.van-nav-bar__title {
  color: #ffffff;
}
.van-nav-bar .van-icon {
  color: #ffffff;
}
.van-hairline--bottom:after {
  border-bottom-width: 0;
}
.van-pull-refresh {
  overflow: auto;
}
</style>
